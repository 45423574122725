
import {
  defineComponent,
  reactive,
  ref,
  UnwrapRef,
  onMounted,
  createVNode,
} from "vue";
import { notification, Modal } from "ant-design-vue";
import {
  RuleObject,
  ValidateErrorEntity,
} from "ant-design-vue/es/form/interface";
import validate from "../services/validator";
import authService from "../services/auth";
import commonService from "../services/common";
import { message } from "ant-design-vue";
import router from "../router";
import {
  CheckCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  CloseCircleFilled,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons-vue";

interface Iuser {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  current_password: string;
  password: string;
  password_confirmation: string;
}

interface FormState {
  agency_name: string;
  address1: string;
  address2: string;
  city: string;
  postal_code: string;
  country: string;
}

function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default defineComponent({
  emits: ["updateProfile"],
  components: {
    EditOutlined,
    // DeleteOutlined,
    CloseCircleFilled,
    LoadingOutlined,
  },
  setup(props, { emit }) {
    const userProfile = ref();
    const changePassword = ref<boolean>(false);
    const profileLoading = ref<boolean>(false);
    const userFormRef = ref();
    const fileList = ref([]);
    const loading = ref(false);
    const pageLoading = ref<boolean>(false);
    const uploadUrl = ref();
    const formState: UnwrapRef<FormState> = reactive({
      agency_name: "",
      address1: "",
      address2: "",
      city: "",
      postal_code: "",
      country: "United Kingdom"
    });
    const formRef = ref();
    const showEditForm = ref<boolean>(false);
    const updateLoading = ref<boolean>(false);
    const allCountries = ref([]);
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const userFormModal: UnwrapRef<Iuser> = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      current_password: "",
      password: "",
      password_confirmation: "",
    });
    const getUserProfile = async () => {
      pageLoading.value = true;
      try {
        const responce = await authService.getProfile();
        if (responce.data.data.success) {
          const data = responce.data.data.profile;
          userProfile.value = data;
          userFormModal.first_name = data.first_name;
          userFormModal.last_name = data.last_name;
          userFormModal.email = data.email;
          userFormModal.phone = data.phone;
          formState.agency_name = data.agency_name;
          formState.address1 = data.address1;
          formState.address2 = data.address2;
          formState.city = data.city;
          formState.postal_code = data.postal_code;
          formState.country = data.country !== null && data.country !== undefined && data.country !== '' ? data.country : 'United Kingdom';
          localStorage.profile = JSON.stringify(data);
          emit("updateProfile");
        }
        pageLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log("Error", error.response);
          await notify("Error", error.response.data.data.message, "error");
          pageLoading.value = false;
        }
      }
    };
    const updatePassword = async () => {
      changePassword.value = true;
    };
    const removePassword = async () => {
      changePassword.value = false;
      userFormModal.current_password = "";
      userFormModal.password = "";
      userFormModal.password_confirmation = "";
    };
    const updateUserProfile = async (values: any) => {
      profileLoading.value = true;
      try {
        const responce = await authService.editUserprofile(values);
        if (responce.data.data.success) {
          if (responce.data.data.msg === "Profile updated successfully.") {
            if (changePassword.value === true) {
              notify(
                "Success",
                "Profile updated successfully, Login with new Password.",
                "success"
              );
              logOut();
            } else {
              getUserProfile();
              notify("Success", "Profile updated successfully.", "success");
            }
          }
        }
        profileLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          profileLoading.value = false;
          await notify("Error", error.response.data.data.msg, "error");
        }
      }
    };

    const logOut = async () => {
      try {
        const res = await authService.logout();
        if (res.data.message === "Successfully logged out") {
          router.push("/login");
          delete localStorage.accessToken;
          delete localStorage.profile;
          localStorage.clear();
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.data.message, "error");
        }
      }
    };
    const onSubmit = (values: any) => {
      userFormRef.value
        .validate()
        .then(async () => {
          updateUserProfile(values);
        })
        .catch((error: ValidateErrorEntity<Iuser>) => {
          console.log("error", error);
        });
    };
    const validatePass = async (rule: RuleObject, value: string) => {
      if (value === "") {
        // eslint-disable-next-line prefer-promise-reject-errors
        // return Promise.reject('Password is required')
      } else {
        if (userFormModal.password_confirmation !== "") {
          userFormRef.value.validateFields("password_confirmation");
        }
        return Promise.resolve();
      }
    };
    const validatePass2 = async (rule: RuleObject, value: string) => {
      if (value === "") {
        // eslint-disable-next-line prefer-promise-reject-errors
        // return Promise.reject('Confirm Password is required')
      } else if (value !== userFormModal.password) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject("Confirm Password does not match with password!");
      } else {
        return Promise.resolve();
      }
    };
    const userFormRules = {
      first_name: [
        { required: true, message: "First name is required", trigger: "blur" },
      ],
      last_name: [
        { required: true, message: "Last name is required", trigger: "blur" },
      ],
      email: [
        { required: true, message: "E-mail is required", trigger: "blur" },
        {
          message: "Please enter a valid email",
          trigger: "change",
          pattern:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        },
      ],
      current_password: [
        {
          required: true,
          message: "Current Password is required",
          trigger: "blur",
        },
      ],
      password: [
        { required: true, message: "Password is required", trigger: "blur" },
        {
          min: 6,
          max: 8,
          message: "Length should be 6 to 8",
          trigger: "change",
        },
        { validator: validatePass, trigger: "change" },
      ],
      password_confirmation: [
        {
          required: true,
          message: "Confirm Password is required",
          trigger: "blur",
        },
        {
          min: 6,
          max: 8,
          message: "Length should be 6 to 8",
          trigger: "change",
        },
        { validator: validatePass2, trigger: "change" },
      ],
    };
    const handleChange = (info: any) => {
      if (info.file.status === "uploading") {
        loading.value = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        // getBase64(info.file.originFileObj, (base64Url: string) => {
        //     imageUrl.value = base64Url;
        // });
        loading.value = false;
        if (info.file.response) {
          if (info.file.response.data.success) {
            ProfileImage(info.file.response.data.image_url);
          }
        }
      }

      if (info.file.status === "error") {
        loading.value = false;
        message.error("upload error");
      }
    };
    const beforeUpload = (file: any) => {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }

      return isLt2M;
    };
    const ProfileImage = async (image: any) => {
      const data = {
        photo_url: image,
      };
      try {
        const res = await authService.profileUpload(data);
        if (res.data.data.success) {
          getUserProfile();
          notify("Success", res.data.data.msg, "success");
        }
      } catch (error: any) {
        if (error.response) {
          notify("Error", "Profile update Failed", "error");
          console.log(error.response);
        }
      }
    };
    const editagency = async (id: any) => {
      showEditForm.value = true;
      getUserProfile();
    };
    const cancelAgencyEdit = async () => {
      showEditForm.value = false;
    };
    const formRules = {
      agency_name: [
        { required: true, message: "Agency name is required", trigger: "blur" },
      ],
      address1: [
        { required: true, message: "Address1 is required", trigger: "blur" },
      ],
    };
    const updateAgency = async (values: any, id: any) => {
      formRef.value
      .validate()
      .then(async () => {
        updateAgencyDetails(values, id);
      })
      .catch((error: ValidateErrorEntity<Iuser>) => {
        console.log("error", error);
      });
    };
    const updateAgencyDetails = async (values: any, id: any) => {
      try {
        updateLoading.value = true;
        const res = await authService.updateAgency(id, values);
        if (res.data.data.success) {
          getUserProfile();
          notify("Success", res.data.data.message, "success");
        }
        updateLoading.value = false;
        showEditForm.value = false;
      } catch (error: any) {
        if (error.response) {          
          if(error.response.data.agency_name[0]){
            console.log(error.response.data.agency_name[0]);
            await notify("Error", error.response.data.agency_name[0], "error");
          } else {
            await notify("Error", error.response.data.data.message, "error");
          }
          updateLoading.value = false;
        }
        
      }
    };
    const filterOption = (input: string, option: any) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };
    const getAllCountries = async () => {
      allCountries.value = [];
      try {
        const res = await commonService.getAllCountries();
        if (res.data.data.success) {
          allCountries.value = res.data.data.Countries;
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };
    onMounted(async () => {
      getUserProfile();
      uploadUrl.value = process.env.VUE_APP_API_URL + "upload-image";
      getAllCountries();
    });
    return {
      userProfile,
      userFormRef,
      userFormRules,
      userFormModal,
      changePassword,
      updatePassword,
      removePassword,
      onSubmit,
      profileLoading,
      notify,
      handleChange,
      fileList,
      loading,
      beforeUpload,
      pageLoading,
      uploadUrl,
      ProfileImage,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      headers: {
        Authorization:
          localStorage.getItem("tokenType") +
          " " +
          localStorage.getItem("accessToken"),
      },
      editagency,
      formState,
      formRef,
      showEditForm,
      cancelAgencyEdit,
      formRules,
      updateAgency,
      updateLoading,
      filterOption,
      allCountries,
    };
  },
});
